<template>
  <MasterDetail
    formTitle="Cadastro Grupo Encargos Previdenciários"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    :contextOptions="options"
    descriptionProperty="grupo"
  ></MasterDetail>
</template>

<script>
import { mapGetters } from 'vuex';
import MasterDetail from "@/components/master-detail.vue";

export default {
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['clients']),
    client: function () {
      return this.getClient();
    },
    resourceUrl: function () {
      return `/v1/rh/clientes/${this.client.clientId}/grupoEncargos`;
    },
    options: function () {
      return [{
        name: "Ver Encargos",
        limit: 1,
        class: { "btn-info": true, "btn-md": true },
        cb: (grupo) => {
          this.$router.push({ name: "dispendio-encargos-previdenciarios-detalhes", params: { grupoId: grupo.id } });
        },
      }];
    },
  },
  created: function () {
    this.opts.clientes = this.clients;
  },
  data: function () {
    return {
      cols: [
        { key: "id", name: "ID", hideInform: true },
        {
          key: "empresaId",
          name: "Empresa",
          sort: 0,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rel: { to: "clientes", key: "id", name: "fantasia" },
          defaultValue: this.$route.params.clientId,
          hideInform: true,
        },
        {
          key: "grupo",
          name: "Grupo",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "padrao",
          name: "Padrão",
          type: this.$fieldTypes.SELECT,
          rel: { to: "padrao", key: "id", name: "nome" },
          editable: false,
          colSize: 6,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        clientes: [],
        padrao: [
          { id: 1, nome: "Sim" },
          { id: 0, nome: "Não" },
        ],
      },
    };
  },
};
</script>